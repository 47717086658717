:root {
	--color-green: #09aaa1;
	--color-blue: #3967c3;
	--color-red: #ff7880;
	--color-light-gray: #f3f3f3;
	--color-medium-gray: #e9e9e9;
	--color-dark-gray: #6e6e6e;
  --color-description: #505050;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	box-sizing: border-box;
	margin: 0;
	font-family: 'Akademie', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
	display: none;
	/* for Chrome, Safari, and Opera */
}

/* TODO clean up unused fonts */

@font-face {
	font-family: 'Akademie';
	src: local('Akademie'), url(./fonts/nb_akademie_regular-webfont.woff) format('woff');
}

@font-face {
	font-weight: 500;
	font-family: 'Akademie';
	src: url(./fonts/nb_akademie_medium-webfont.woff) format('woff');
}

@font-face {
	font-weight: 500;
	font-style: italic;
	font-family: 'Akademie';
	src: local('Akademie'), url(./fonts/nb_akademie_medium_italic-webfont.woff) format('woff');
}

@font-face {
	font-style: italic;
	font-family: 'Akademie';
	src: local('Akademie'), url(./fonts/nb_akademie_italic-webfont.woff) format('woff');
}

@font-face {
	font-weight: 300;
	font-family: 'Akademie';
	src: local('Akademie'), url(./fonts/nb_akademie_light-webfont.woff) format('woff');
}

@font-face {
	font-weight: 300;
	font-style: italic;
	font-family: 'Akademie';
	src: local('Akademie'), url(./fonts/nb_akademie_light_italic-webfont.woff) format('woff');
}

@font-face {
	font-weight: 600;
	font-family: 'Akademie';
	src: local('Akademie'), url(./fonts/nb_akademie_semibold-webfont.woff) format('woff');
}

@font-face {
	font-weight: 600;
	font-style: italic;
	font-family: 'Akademie';
	src: local('Akademie'), url(./fonts/nb_akademie_semibold_italic-webfont.woff) format('woff');
}

@font-face {
	font-family: 'Akademie-Book';
	src: local('Akademie-Book'), url(./fonts/nb_akademie_pro_book-webfont.woff) format('woff');
}

@font-face {
	font-style: italic;
	font-family: 'Akademie-Book';
	src: local('Akademie-Book'), url(./fonts/nb_akademie_book_italic-webfont.woff) format('woff');
}

@font-face {
	font-family: 'International';
	src: local('International'), url(./fonts/nbinternationalproreg-webfont.woff) format('woff');
}

@font-face {
	font-weight: 500;
	font-family: 'International';
	src: local('International'), url(./fonts/nbinternationalpromed-webfont.woff) format('woff');
}
